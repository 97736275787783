"use client"

import React, { createContext, useContext, useState } from 'react'

interface AuthSheetContextType {
  isSheetOpen: boolean
  setIsSheetOpen: (open: boolean) => void
  avatarUrl: string
  setAvatarUrl: React.Dispatch<React.SetStateAction<string>>
  userName: string
  setUserName: React.Dispatch<React.SetStateAction<string>>
  userEmail: string
  setUserEmail: React.Dispatch<React.SetStateAction<string>>
}

const AuthSheetContext = createContext<AuthSheetContextType>({
  isSheetOpen: false,
  setIsSheetOpen: () => {},
  avatarUrl: '',
  setAvatarUrl: () => {},
  userName: '',
  setUserName: () => {},
  userEmail: '',
  setUserEmail: () => {},
})

export function AuthSheetProvider({ children }: { children: React.ReactNode }) {
  const [isSheetOpen, setIsSheetOpen] = useState(false)
  const [avatarUrl, setAvatarUrl] = useState<string>('')
  const [userName, setUserName] = useState<string>('')
  const [userEmail, setUserEmail] = useState<string>('')

  return (
    <AuthSheetContext.Provider value={{ isSheetOpen, setIsSheetOpen, avatarUrl, setAvatarUrl, userName, setUserName, userEmail, setUserEmail }}>
      {children}
    </AuthSheetContext.Provider>
  )
}

export function useAuthSheet() {
  const context = useContext(AuthSheetContext)
  if (context === undefined) {
    throw new Error('useAuthSheet must be used within an AuthSheetProvider')
  }
  return context
}
