'use client';

import React, { useState } from 'react';
import axios from 'axios';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/Button';
import { toast } from "sonner";

interface RegisterFormProps {
  onToggleForm: () => void;
  onSuccess?: () => void;
}

const Register: React.FC<RegisterFormProps> = ({ onToggleForm, onSuccess }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const validateForm = () => {
    if (!email.includes('@')) {
      toast.error('Please enter a valid email address.', {
        duration: 3500
      });
      return false;
    }
    if (password.length < 6) {
      toast.error('Password must be at least 6 characters.', {
        duration: 3500
      });
      return false;
    }
    return true;
  };

  const resetForm = () => {
    setUsername('');
    setEmail('');
    setPassword('');
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const response = await axios.post('/api/register', {
        username,
        email,
        password,
      });

      if (response.status === 200) {
        toast.success('Registration successful!');
        resetForm();
        onSuccess?.();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message = error.response?.data?.message || 'Registration failed';
        toast.error(message, {
          duration: 3500
        });
      } else {
        toast.error('An unexpected error occurred', {
          duration: 3500
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <Input
            type="text"
            id="username"
            placeholder='Username'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="w-full bg-background/50"
            required
          />
        </div>
        <div className="space-y-2">
          <Input
            type="email"
            id="email"
            placeholder='email@example.com'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full bg-background/50"
            required
          />
        </div>
        <div className="space-y-2">
          <div className="relative">
            <Input
              type={showPassword ? 'text' : 'password'}
              id="password"
              placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full bg-background/50"
              required
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute right-3 top-1/2 -translate-y-1/2 text-sm text-primary-foreground/70 hover:text-primary-foreground focus:outline-none"
            >
              {showPassword ? 'Hide' : 'Show'}
            </button>
          </div>
        </div>
        <Button type="submit" className="w-full" disabled={isLoading}>
          {isLoading ? 'Registering...' : 'Register'}
        </Button>
        <Button
          type="button"
          variant="ghost"
          className="w-full"
          onClick={onToggleForm}
        >
          Already have an account? Login
        </Button>
      </form>
    </div>
  );
};

export default Register;
